




































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IGroupProfile } from '@/interfaces';
import { readAdminGroups } from '@/store/group/getters';
import { dispatchGetGroups, dispatchRemoveGroup } from '@/store/group/actions';

@Component
export default class AdminGroups extends Vue {
  public headers = [
    {
      text: '班级名称',
      sortable: true,
      value: 'title',
      align: 'left',
    },
    {
      text: '所属学校',
      sortable: true,
      value: 'org_unit',
      align: 'left',
    },
    {
      text: '操作',
      value: 'id',
      align: 'center',
    },
  ];
  get groups() {
    return readAdminGroups(this.$store);
  }

  public async mounted() {
    // await dispatchGetUsers(this.$store);
    console.log('groups mounted....');
    await dispatchGetGroups(this.$store);
  }

  public async deleteById(batchId) {
    const res = 'OK';

    // await this.$dialog.confirm({
    //   text: 'Do you really want to exit?',
    //   title: 'Warning'
    // })
    console.log('res: ' + res);
    if (res === 'OK' ) {
      await dispatchRemoveGroup(this.$store, batchId);
      // this.$router.push('/main/admin/groups/all');
    }
  }
}
