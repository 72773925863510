
















































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Store,
} from 'vuex';
import {
    IResultProfile,
} from '@/interfaces';
import {
    readAdminResults,
} from '@/store/result/getters';
import {
    dispatchGetResults,
    dispatchRemoveResult,
    dispatchUploadResult,
} from '@/store/result/actions';
import UploadButton from '@/components/UploadButton.vue';


@Component({
    components: {
        UploadButton,
    },
})
export default class AdminResults extends Vue {
    public headers = [{
            text: '名称',
            sortable: true,
            value: 'title',
            align: 'left',
        },
        {
            text: '批次',
            sortable: true,
            value: 'batches',
            align: 'left',
        },
        {
            text: '学校',
            sortable: true,
            value: 'orgUnit',
            align: 'left',
        },
        {
            text: '班级',
            sortable: true,
            value: 'group',
            align: 'left',
        },
        {
            text: '姓名',
            sortable: true,
            value: 'owner',
            align: 'left',
        },
        {
            text: '创建时间',
            sortable: true,
            value: 'atCreated',
            align: 'left',
        },
        {
            text: '检查结果',
            sortable: false,
            value: 'results',
            align: 'center',
        },
        // {
        //   text: '测试',
        //   sortable: false,
        //   value: 'results',
        //   align: 'left',
        // },
        {
            text: '操作',
            value: 'id',
            align: 'center',
        },
    ];

    public search: string = '';
    public pagination: any = {};
    public totalResults: number = 0;
    public loading: boolean = false;

    get results() {
        return readAdminResults(this.$store);
    }

    public async importFile(files: FileList, e: Event) {
        console.info('import checking');
        console.info(files);

        const self = this;

        // console.log(JSON.stringify(e));
        const response = await new Promise( async (resolve, reject) => {
                const resp = await dispatchUploadResult(this.$store, files);
                console.log('upload resp', resp);
                resolve(resp);
            }).then(function(resp) {
                console.log('next resp', resp);

                console.info(self);

                self.search = '*';
            }).catch(function(err) {
                console.log('good.json error', err.message); // never called
        });
    }

    public async mounted() {
        // await dispatchGetUsers(this.$store);
        console.log('results mounted....');

        const params = {
            keyword: this.search,
        };
        await dispatchGetResults(this.$store, params);
    }

    // @Watch('pagination')
    // onChangePagination(newVal: any, oldVal: any){
    //   // todo...
    //   this.loading = true;

    //   console.warn("pagination old: ", oldVal);
    //   console.warn("pagination new: ", newVal);

    //   this.loading = false;
    // }

    @Watch('search')
    public async onChangeSearch(newVal: any, oldVal: any) {
        // todo...
        this.loading = true;

        const params = {
            keyword: this.search,
        };
        await dispatchGetResults(this.$store, params);

        this.loading = false;
    }

    public async deleteById(batchId) {
        const res = 'OK';

        // await this.$dialog.confirm({
        //   text: 'Do you really want to exit?',
        //   title: 'Warning'
        // })
        console.log('res: ' + res);
        if (res === 'OK') {
            await dispatchRemoveResult(this.$store, batchId);
        }
    }
}
