


























import { Component, Vue } from 'vue-property-decorator';
import {
  IGroupProfile,
  IGroupProfileUpdate,
  IGroupProfileCreate,
} from '@/interfaces';
import { dispatchGetGroups, dispatchCreateGroup } from '@/store/group/actions';

@Component
export default class CreateGroup extends Vue {
  public valid = false;
  public orgUnitId: number = 1;
  public title: string = '';

  public async mounted() {
    await dispatchGetGroups(this.$store);
    this.reset();
  }

  public reset() {
    this.title = '';
    this.orgUnitId = 1;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IGroupProfileCreate = {
        title: this.title,
      };
      if (this.orgUnitId) {
        updatedProfile.org_unit_id = this.orgUnitId;
      }

      await dispatchCreateGroup(this.$store, updatedProfile);
      this.$router.push('/main/admin/groups/all');
    }
  }
}
