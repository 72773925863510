




































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IMemberProfile } from '@/interfaces';
import { readAdminMembers } from '@/store/member/getters';
import { dispatchGetMembers } from '@/store/member/actions';

@Component
export default class AdminMembers extends Vue {
  public headers = [
    {
      text: '账号',
      sortable: true,
      value: 'login',
      align: 'left',
    },
    {
      text: '昵称',
      sortable: true,
      value: 'nickName',
      align: 'left',
    },
    {
      text: '头标',
      sortable: true,
      value: 'avtar',
      align: 'left',
    },
    {
      text: '创建日期',
      sortable: true,
      value: 'atCreated',
      align: 'left',
    },
    {
      text: '是否启用',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: '操作',
      value: 'id',
    },
  ];
  get members() {
    return readAdminMembers(this.$store);
  }

  public async mounted() {
    await dispatchGetMembers(this.$store);
  }
}
