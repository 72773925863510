


















































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Store,
} from 'vuex';
import {
    ITicketProfile,
} from '@/interfaces';
import {
    readAdminTickets,
} from '@/store/ticket/getters';
import {
    dispatchGetTickets,
    dispatchRemoveTicket,
} from '@/store/ticket/actions';

@Component
export default class AdminTickets extends Vue {
    public headers = [{
            text: '名称',
            sortable: true,
            value: 'title',
            align: 'left',
        },
        {
            text: '批次',
            sortable: true,
            value: 'batches',
            align: 'left',
        },
        {
            text: '类型',
            sortable: true,
            value: 'category',
            align: 'left',
        },
        {
            text: '来源',
            sortable: true,
            value: 'channel',
            align: 'left',
        },
        {
            text: '会员名',
            sortable: true,
            value: 'owner',
            align: 'left',
        },
        {
            text: '创建时间',
            sortable: true,
            value: 'atCreated',
            align: 'left',
        },
        {
            text: '状态',
            sortable: true,
            value: 'status',
            align: 'left',
        },
        {
            text: '操作',
            value: 'id',
            align: 'center',
        },
    ];

    public search: string = '';
    public pagination: any = {};
    public totalDesserts: number = 0;
    public loading: boolean = false;

    get tickets() {
        return readAdminTickets(this.$store);
    }

    public async mounted() {
        // await dispatchGetUsers(this.$store);
        console.log('tickets mounted....');

        const params = {
            keyword: this.search,
        };
        await dispatchGetTickets(this.$store, params);
    }

    // @Watch('pagination')
    // onChangePagination(newVal: any, oldVal: any){
    //   // todo...
    //   this.loading = true;

    //   console.warn("pagination old: ", oldVal);
    //   console.warn("pagination new: ", newVal);

    //   this.loading = false;
    // }

    @Watch('search')
    public async onChangeSearch(newVal: any, oldVal: any) {
        // todo...
        this.loading = true;

        const params = {
            keyword: this.search,
        };
        await dispatchGetTickets(this.$store, params);

        this.loading = false;
    }

    public async deleteById(batchId) {
        const res = 'OK';

        // await this.$dialog.confirm({
        //   text: 'Do you really want to exit?',
        //   title: 'Warning'
        // })
        console.log('res: ' + res);
        if (res === 'OK') {
            await dispatchRemoveTicket(this.$store, batchId);
        }
    }
}
