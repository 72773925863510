








































































import { Component, Vue } from 'vue-property-decorator';
import { IBatchProfile, IBatchProfileUpdate } from '@/interfaces';
import { dispatchGetBatches, dispatchUpdateBatch } from '@/store/batches/actions';
import { readAdminBatch } from '@/store/batches/getters';

@Component
export default class EditBatch extends Vue {
  public valid = true;
  public batches: string = '';
  public title: string = '';
  public atOpen: string = new Date().toISOString().substr(0, 10);
  public atClose: string = '';
  public menu1 = false;
  public menu2 = false;

  public async mounted() {
    await dispatchGetBatches(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.batch) {
      this.title = this.batch.title;
      this.batches = this.batch.batches;
      this.atOpen = this.batch.at_open;
      this.atClose = this.batch.at_close;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IBatchProfileUpdate = {};
      if (this.title) {
        updatedProfile.title = this.title;
      }
      if (this.batches) {
        updatedProfile.batches = this.batches;
      }
      updatedProfile.at_open = this.atOpen;
      updatedProfile.at_close = this.atClose;

      await dispatchUpdateBatch(
        this.$store, { id: this.batch!.id, batch: updatedProfile });
      this.$router.push('/main/admin/batches/all');
    }
  }

  get batch() {
    return readAdminBatch(this.$store)(+this.$router.currentRoute.params.id);
  }
}
