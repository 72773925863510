






















































































import { Component, Vue } from 'vue-property-decorator';
import { IMemberProfile, IMemberProfileUpdate } from '@/interfaces';
import { dispatchGetMembers, dispatchUpdateMember } from '@/store/member/actions';
import { readAdminMember } from '@/store/member/getters';

@Component
export default class EditMember extends Vue {
  public valid = true;
  public login: string = '';
  public nickName: string = '';
  public isActive: boolean = true;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';

  public async mounted() {
    await dispatchGetMembers(this.$store);
    this.reset();
  }

  public reset() {
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';
    this.$validator.reset();
    if (this.member) {
      this.login = this.member.login;
      this.nickName = this.member.nick_name;
      this.isActive = this.member.is_active;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IMemberProfileUpdate = {
        login: this.login,
      };

      if (this.nickName) {
        updatedProfile.nick_name = this.nickName;
      }
      updatedProfile.is_active = this.isActive;
      if (this.setPassword) {
        updatedProfile.token = this.password1;
      }
      await dispatchUpdateMember(this.$store, { id: this.member!.id, member: updatedProfile });
      this.$router.push('/main/admin/members/all');
    }
  }

  get member() {
    return readAdminMember(this.$store)(+this.$router.currentRoute.params.id);
  }
}
