


























import {
    Component,
    Vue,
} from 'vue-property-decorator';
import {
    IGroupProfile,
    IGroupProfileUpdate,
} from '@/interfaces';
import {
    dispatchGetGroups,
    dispatchUpdateGroup,
} from '@/store/group/actions';
import {
    readAdminGroup,
} from '@/store/group/getters';

@Component
export default class EditGroup extends Vue {
    public valid = true;
    public title: string = '';

    public async mounted() {
        await dispatchGetGroups(this.$store);
        this.reset();
    }

    public reset() {
        this.$validator.reset();
        if (this.group) {
            this.title = this.group.title;
        }
    }

    public cancel() {
        this.$router.back();
    }

    public async submit() {
        if (await this.$validator.validateAll()) {
            const updatedProfile: IGroupProfileUpdate = {};
            if (this.title) {
                updatedProfile.title = this.title;
            }

            await dispatchUpdateGroup(
                this.$store, {
                    id: this.group!.id,
                    group: updatedProfile,
                });
            this.$router.push('/main/admin/groups/all');
        }
    }

    get group() {
        return readAdminGroup(this.$store)(+this.$router.currentRoute.params.id);
    }
}
