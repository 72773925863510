





















































import {
    Component,
    Vue,
} from 'vue-property-decorator';
import {
    IResultProfile,
    IResultProfileUpdate,
} from '@/interfaces';
import {
    dispatchGetResults,
    dispatchUpdateResult,
} from '@/store/result/actions';
import {
    readAdminResult,
} from '@/store/result/getters';

@Component
export default class EditResult extends Vue {
    public valid = true;
    public title: string = '';
    public batches: string = '';
    public orgUnit: string = '';
    public group: string = '';
    public owner: string = '';
    public atCreated: string = '';
    public results: any = {};

    public async mounted() {
        await dispatchGetResults(this.$store, null);
        this.reset();
    }

    public reset() {
        this.$validator.reset();
        if (this.result) {
            this.title = this.result.title;
            this.batches = this.result.batches;
            this.orgUnit = this.result.org_unit;
            this.group = this.result.group;
            this.owner = this.result.owner;
            this.results = this.result.results;
            this.atCreated = new Date(this.result.at_created).toLocaleString();
        }
    }

    public cancel() {
        this.$router.back();
    }

    public async submit() {
        if (await this.$validator.validateAll()) {
            const updatedProfile: IResultProfileUpdate = {};
            if (this.title) {
                updatedProfile.title = this.title;
            }

            updatedProfile.batches = this.batches;
            updatedProfile.org_unit = this.orgUnit;
            updatedProfile.group = this.group;
            updatedProfile.owner = this.owner;
            updatedProfile.results = this.results;

            await dispatchUpdateResult(
                this.$store, {
                    id: this.result!.id,
                    result: updatedProfile,
                });
            this.$router.push('/main/admin/results');
        }
    }

    get result() {
        return readAdminResult(this.$store)(+this.$router.currentRoute.params.id);
    }
}
