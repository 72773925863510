




















































import {
    Component,
    Vue,
} from 'vue-property-decorator';
import {
    IResultProfile,
    IResultProfileUpdate,
    IResultProfileCreate,
} from '@/interfaces';
import {
    dispatchGetResults,
    dispatchCreateResult,
} from '@/store/result/actions';

@Component
export default class CreateResult extends Vue {
    public valid = false;
    public title: string = '';
    public batches: string = '';
    public orgUnit: string = '';
    public group: string = '';
    public owner: string = '';
    public atCreated: string = '';
    public results: any = {
        R_E_0: 1.0,
        L_E_0: 1.0,
        R_E_1: 1.0,
        L_E_1: 1.0,
    };

    public async mounted() {
        await dispatchGetResults(this.$store, '*');
        this.reset();
    }

    public reset() {
        this.title = '';
        this.batches = '';
        this.orgUnit = '';
        this.group = '';
        this.owner = '';

        this.results = {
            R_E_0: 1.0,
            L_E_0: 1.0,
            R_E_1: 1.0,
            L_E_1: 1.0,
        };
    }

    public cancel() {
        this.$router.back();
    }

    public async submit() {
        if (await this.$validator.validateAll()) {
            const updatedProfile: IResultProfileCreate = {
                title: this.title,
                owner: this.owner,
            };

            if (this.batches) {
                updatedProfile.batches = this.batches;
            }

            if (this.orgUnit) {
                updatedProfile.org_unit = this.orgUnit;
            }
            if (this.group) {
                updatedProfile.group = this.group;
            }
            if (this.results) {
                updatedProfile.results = this.results;
            }

            await dispatchCreateResult(this.$store, updatedProfile);
            this.$router.push('/main/admin/results');
        }
    }
}
