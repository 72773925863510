





































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUnitProfile } from '@/interfaces';
import { readAdminUnits } from '@/store/unit/getters';
import { dispatchGetUnits, dispatchRemoveUnit } from '@/store/unit/actions';

@Component
export default class AdminUnits extends Vue {
  public headers = [
    {
      text: '编号',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: '名称',
      sortable: true,
      value: 'title',
      align: 'left',
    },
    {
      text: '类型',
      sortable: true,
      value: 'category',
      align: 'left',
    },
    {
      text: '是否启用',
      sortable: true,
      value: 'isActive',
      align: 'left',
    },
    {
      text: '操作',
      value: 'id',
      align: 'center',
    },
  ];
  get units() {
    // return readAdminUsers(this.$store);
    return readAdminUnits(this.$store);
  }

  public async mounted() {
    // await dispatchGetUsers(this.$store);
    console.log('units mounted....');
    await dispatchGetUnits(this.$store);
  }

  public async deleteById(unitId) {
    const res = 'OK';

    // await this.$dialog.confirm({
    //   text: 'Do you really want to exit?',
    //   title: 'Warning'
    // })
    console.log('res: ' + res);
    if (res === 'OK' ) {
      await dispatchRemoveUnit(this.$store, unitId);
      // this.$router.push('/main/admin/companies/all');
    }
  }
}
